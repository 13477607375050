import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@mui/material/Typography';
import ImageList from '../components/ImageList';
import Seo from '../components/Seo';

const Paintings = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, JPG]
              )
            }
          }
        }
      }
    }
  `);
  const [node, setNode] = React.useState(null);
  const images = data.allFile.edges.sort((a, b) => a.node.base.slice(0, 2) - b.node.base.slice(0, 2));
  return (
    <>
      <Seo title="Paintings Still Lives" />
      <Typography variant="h4" align="center" sx={{ fontWeight: 700, marginBottom: '32px' }}>Still Lives</Typography>
      <div>
        <ImageList images={images.slice(20, 21)} setNode={setNode} selected={node} width={400} height={460} bigWidth={600} bigHeight={690} />
        <ImageList images={images.slice(21)} setNode={setNode} selected={node} width={450} height={330} bigWidth={600} bigHeight={440} />
      </div>
    </>
  );
};

export default Paintings;
